import * as React from "react"
import { useCallback } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import FileUpload from "../components/form/FileUpload"
import Map from "../components/common/Map"
import AdditionalForms from "../components/common/AdditionalForms"
import InputField from "../components/form/InputField"
import FormWrapper from "../components/form/FormWrapper"
import useFormState from "../components/form/hooks/useFormState"
import AsAdvertisedOn from "../components/common/AsAdvertisedOn"
import isBrandWeBuy from "../components/form/validators/isBrandWeBuy"
import isNotEmpty from "../components/form/validators/isNotEmpty"
import cascade from "../components/form/validators/cascade"
import HaveQuestions from "../components/common/HaveQuestions"
import HowItWorks from "../components/common/HowItWorks"
import WhatAreYourOptions from "../components/common/WhatAreYourOptions"

const WatchQuoteForm = () => {
  const CANONICAL_PATH_NAME = "/what-is-my-watch-worth/"
  if (typeof window !== `undefined` && window && window.location.pathname !== CANONICAL_PATH_NAME) {
    window.history.replaceState(null, null, CANONICAL_PATH_NAME + (window.location?.search || ""))
  }
  const [form, setForm, updateForm] = useFormState({
    name: "",
    phone: "",
    brand: "",
    email: "",
    description: "",
    photos: [],
  })
  const updateUploadedFiles = useCallback(
    files =>
      setForm(f => {
        return { ...f, photos: files }
      }),
    [setForm]
  )
  return (
    <Layout className={"mx-auto"} breadcrumbs={false}>
      <Seo
        title="What is my watch worth? Watch Quote Form"
        description="Discover the true value of your watch at SellUsYourJewelry.com. Find out what your watch is worth and get top dollar for it today! Click here!"
        canonical={CANONICAL_PATH_NAME}
      />

      <section
        id="Watch Instant Quote Form"
        className="bg-gradient-to-tl from-[#78a08a] to-[#b0cebe] pt-20 md:pt-5 lg:pt-0 py-10 md:py-20"
      >
        <div className="text-center text-3xl font-black text-white md:text-5xl xl:text-6xl w-full inline-block pb-5 lg:py-10 uppercase px-5">
          THE SOLUTION <span className="font-normal">to selling your jewelry</span>
        </div>
        <div className="flex flex-col items-center justify-center lg:flex-row">
          <div className="flex w-10/12 mx-auto flex-col justify-center items-center xl:px-20">
            <FormWrapper
              className={"bg-white p-5 2xl:w-10/12 pl-5 2xl:pl-[25%] relative rounded-xl shadow-xl"}
              name={"What is my watch worth"}
              action={"/thank-you/"}
              form={form}
              category={"watch"}
            >
              <div className="flex items-center flex-col md:flex-row 2xl:min-w-[500px] w-full 2xl:w-1/3 justify-center 2xl:absolute -bottom-[4%] 2xl:right-[calc(60%+5vw)] z-10 ">
                <StaticImage
                  src={"../images/sell-my-watch-form-header.png"}
                  alt={"Watch Quote Form image"}
                  loading={"lazy"}
                  placeholder={"none"}
                  className="w-full md:w-1/2 2xl:w-full"
                />
              </div>
              <h1 className="text-center text-3xl uppercase hidden 2xl:absolute top-10 left-5 2xl:max-w-[28%] 2xl:block ">
                WHAT IS YOUR WATCH WORTH?
              </h1>
              <p className="text-center 2xl:absolute top-32 2xl:px-5 left-5 2xl:max-w-[28%] 2xl:block">
                Fill out the simple form below to receive a free watch quote!
              </p>

              <div className="flex flex-col space-y-2">
                <InputField
                  name={"name"}
                  label={"Name"}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={"phone"}
                  label={"Phone"}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={"brand"}
                  label={"Brand & Model"}
                  form={form}
                  onChange={updateForm}
                  validator={cascade(isNotEmpty, isBrandWeBuy)}
                />
                <InputField
                  name={"email"}
                  label={"E-Mail"}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={"description"}
                  label={"Description"}
                  type={"textarea"}
                  rows={"5"}
                  form={form}
                  onChange={updateForm}
                  className="pb-5 lg:pb-0"
                />
                <FileUpload
                  id={"photos"}
                  label={"Add photos"}
                  name={"photos"}
                  accept={".jpg,.png,.jpeg"}
                  updateFilesCb={updateUploadedFiles}
                  multiple
                />
                * Max. file size: 40 MB, Max. files: 5.
                <br />
                <br />
                Please attach photos of items & appraisals/certificates. The red 'X's are normal.
              </div>
              <div className="w-full flex items-center justify-center 2xl:justify-start">
                <button className="bg-[#816230] hover:bg-accentMd duration-200 text-white px-7 py-3 rounded-full my-5">
                  SUBMIT FORM
                </button>
              </div>
            </FormWrapper>
          </div>
        </div>
      </section>
      <WhatAreYourOptions />
      <HowItWorks className={"w-10/12 mx-auto max-w-[1366px] "} />

      <AdditionalForms />

      <div className="w-10/12 mx-auto max-w-[1366px] ">
        <p className="w-full text-lg leading-loose ">
          We are one of the largest buyers and sellers of used Rolex watches, Cartier watches, Patek
          Philippe watches and high end jewelry since 1980. Don’t just go to any jeweler or pawnshop
          to sell your luxury watch. Come to sellusyourjewelry.com the purchasing division of Gray &
          Sons Jewelers. The top pre-owned Hublot and Audemars Piguet watch buyers in the United
          States since 1980. Known as the top estate jewelry and Swiss watch buyer rated A+ by the
          Better Business Bureau, giving you peace of mind and confidence that we will offer you the
          best price for your Omega, Breitling, and many more fine watches. At Sell Us Your Jewelry,
          we are always interested in buying like new, worn, unused, and used fine watches. So if
          you’re ready to get the highest value for your luxury watch; come to
          sellusyourjewelry.com.
        </p>

        <br />
        <Link to="/ez-ship-box-request-form/" className="hoverScale flex w-full justify-center ">
          <StaticImage
            src={"../images/Shipping-Box-request.jpg"}
            alt={"Accurate Appraisal for Jewelry Shipping"}
            loading={"eager"}
          />
        </Link>
      </div>

      <section className="py-20">
        <AsAdvertisedOn />
      </section>

      <div className="w-full py-10 shadow-lg" />

      <HaveQuestions />

      <section className={"mt-16"}>
        <Map />
      </section>
    </Layout>
  )
}

export default WatchQuoteForm
